#Nav-Grid{
  grid-template-areas: "component-navigation component-core-apps";
}

html,
body{
  background-color: rgb(231, 231, 231);
}

footer.footer{
  background-color: transparent;
}

#Nav-Grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#CoreNavigationGrid{
  display: flex;
  flex-direction: row-reverse;
}

#Logo-Container{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  height: 0px;
}

#Logo-Image{
  top: -.8rem;
  border-radius: 50%;
  height: 6rem;
  width: 10rem;
  padding: 1.5rem;
  background-color:white;
  position: relative;
  margin: auto;
}

#Logo-Image a{
  position: relative;
  top: -1.2rem;
}

@media (max-width: 900px){
  #Logo-Container{
    display: none;
  }
}


@media (max-width: 400px){
  #Nav-Grid{
  display: grid;
  grid-template-columns: 1fr 3rem;
}
}
